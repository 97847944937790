import React from 'react';

function Footer() {
	return (
		<footer>
			<p>Made in 2021 by JP Eiler</p>
		</footer>
	);
}

export default Footer;
